import React from "react"
import Layout from "../components/layout"
import LongListCriteria from "../components/pages/criteria/long-list"
import ShortListCriteria from "../components/pages/criteria/short-list"
import PageHeader from "../components/utils/page-header"

const Criteria = ({ location }) => {
  return (
    <Layout title="Evaluation Critetria" url={location.pathname}>
      <PageHeader
        topText="Making"
        bottomText="the list"
        title="Evaluation Criteria"
      />
      <ShortListCriteria />
      <LongListCriteria />
    </Layout>
  )
}

export default Criteria

import React from "react"
import PropTypes from "prop-types"

const LongListItem = ({ criteria }) => {
  return (
    <li className="flex my-6">
      <hr className="inline-block md:w-8 w-6 mt-4 flex-shrink-0 flex-grow-0 bg-black text-black h-px  md:ml-28 md:mr-8 mr-4" />
      <p className="mt-0 ">{criteria}</p>
    </li>
  )
}

LongListItem.propTypes = {
  criteria: PropTypes.string.isRequired,
}

export default LongListItem

import React from "react"
import Container from "../../../utils/container"
import { list } from "./list"
import ShortListItem from "./item"

const ShortListCriteria = () => {
  const showShortListCriteria = list.map((list, index) => (
    <ShortListItem
      key={index}
      title={list.title}
      description={list.description}
      color={list.color}
    />
  ))
  return (
    <Container>
      <p>
        Our evaluation of brands is based on how well they exemplify the tenets
        of disruptive brand growth through ingenious storytelling, a vibrant
        brand tribe, and an exceptional brand experience that drives evident
        growth.
      </p>
      {showShortListCriteria}
    </Container>
  )
}

export default ShortListCriteria

import React from "react"
import Container from "../../../utils/container"
import LongListItem from "./item"

const LongListCriteria = () => {
  const list = [
    "Not more than 10 years of existence or active  operation.",
    "Either founded, co-founded, owned or co-owned by an individual who is a citizen of an African country.",
    "Active in at least one African country and serves the local market. ",
    "Be of an organization or product built around a B2C innovation primarily powered by technology.",
  ]

  const showList = list.map((item, index) => (
    <LongListItem criteria={item} key={index} />
  ))
  return (
    <Container>
      <p>
        To make our first long list, brands had to fulfil the following criteria
        which were selected to fulfil the ideology of the report which is built
        around being new, African and tech-powered.{" "}
      </p>
      <ul>{showList}</ul>
    </Container>
  )
}

export default LongListCriteria

import React from "react"
import PropTypes from "prop-types"
import ctl from "@netlify/classnames-template-literals"

const ShortListItem = ({ title, description, color }) => {
  const list_style = ctl(`
  flex 
  items-start 
  my-12
  `)

  const list_heading_style = ctl(`
  font-semibold 
  font-serif 
  md:text-3xl 
  text-2xl
  `)

  const list_icon_style = ctl(`
  flex-shrink-0 
  md:ml-12 
  md:mr-8 
  mr-4 
  md:mt-2 
  mt-1
  `)
  return (
    <div className={list_style}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={list_icon_style}
      >
        <rect width="15" height="15" fill={color} />
      </svg>

      <div>
        <h3 className={list_heading_style}>{title}</h3>
        <p className="mt-1">{description}</p>
      </div>
    </div>
  )
}

ShortListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default ShortListItem

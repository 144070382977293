export const list = [
  {
    title: "Brand Experience",
    description:
      "Brands will be scored based on the quality of their expression across online and offline touchpoints. Brands that have great brand experiences will have a distinct, memorable, and simple visual identity system that is consistently expressed across ads, physical spaces, posters, websites, app and other touchpoints. We will take into account the adoption of product design and brand design principles in deploying brand experiences.",
    color: "#D31111",
  },
  {
    title: "Ingenious Storytelling",
    description:
      "Brands will be scored based on how well they tell their story and maintain humanity in a digitally-driven society.  In judging this, we will pay attention to the consistency of brand narratives, the authenticity of brand tone and level of empathy deployed in communication. ",
    color: "#FFB612",
  },
  {
    title: "Brand Tribe",
    description:
      "We will take into account how brands have tapped into current social realities to inspire and fuel community; building upon great customer experiences to build trust and make fans out of customers.",
    color: "#557FCA",
  },
]
